.Background-Dark {
  background-color: #282c34;
}

.Background-Transparent {
  background-color: rgba(0, 0, 0, 0);
}

.Test {
  background-color: blue;
}

.Card {
  margin: auto;
  /* width: 90%; */
  margin-bottom: 1em;
  margin-top: 1em;
}

.Body {
  align-items: center;
}

.Align-Right {
  margin-left: auto;
  margin-right: 0;
}

.Champion-Items {
  display: flex;
}

.Champion-Item-Container {
  display: inline-block;
}

.Display-Wide{
  display: inline-flex;
  width: 100%;
}

.Composition-Box {

  border-color: black;
  border-style: groove none none groove;
  border-width: 3px;
  margin: 30px 2px 0px 2px;
}

.Composition-Name {
  text-align: left;
  font-size: larger;
  color: white;
  font-weight: bold;
  padding: 2px 2px 2px 10px;
}

.Champion-Name-Large {
  color: white;
  white-space: nowrap;
  margin-bottom: 0.3em;
}

.Champion-Name-Medium {
  color: white;
  font-size: small;
  white-space: nowrap;
  margin-bottom: 0.3em;
}

.Champion-Name-Small {
  color: white;
  white-space: nowrap;
  font-size: x-small;
  margin-bottom: 0.3em;
}

.StarterItems-Text {
  text-align: center;
  color: white;
  padding: 15px 2px 2px 10px;
}

.Traits-Text {
  text-align: center;
  color: white;
  padding: 5px 2px 2px 2px;
}

.Placement-Text {
  text-align: center;
  color: white;
  padding: 0px 0px 5px 0px;
}

.Placement-Box {
  padding: 0px 0px 0px 10px;
}

.Card-grid {
  display: inline-flex;
}

.Align-Items-End {
  align-items: flex-end;
  align-content: flex-end;
}

.Trait-Image {
  width: 100%;
  padding: 2px 2px 2px 2px;
}

.flexbox {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: center;
  gap: 3px;

  align-items: center;
}

.trait-flexbox {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: center;
  gap: 5px;
}

.general-text {
  text-align: center;
  color: white;
}

.StarterItem-Image {
  width: 15%;
  padding: 5px;
}

.Width-125 {
  width: 12.5%;
}

.line {
  margin-top: 1em;
  margin-bottom: 1em;
  border-bottom: 2px dotted rgba(0, 0, 0, 0.315);
}

.Width-70 {
  width: 70%;
}

.Width-15 {
  width: 15%;
}

.Width-35 {
  width: 35%;
}

.Width-40 {
  width: 40%;
}

.Width-50 {
  width: 50%;
}

.Width-30 {
  width: 30%;
}

.Width-20 {
  width: 20%;
}

.Auto-Align {
  display: flex;
  width: 100%;
}

.Width-60 {
  width: 60%;
}

.Height-fix {
  height: 40em;
}

.Display-Flex {
  display: flex;
  align-items: flex-start;
  align-content: center;
  width: inherit;
}

.Item-Tooltip {
  width: 30px;
  padding: 5px;
}

.text-center {
  text-align: center;
  color: white;
}

.plus-for-tooltip {
  font-size: bold;
  color: white;
}

.Item-Component {
  float: left;
  width: 25px;
  padding: 2px;
}

.tier-star {
  color: #c6cc25;
}

.augments {
  margin-bottom: 0.5em;
}

.champion-box {
  display: flex;
  flex-direction: column;
}

.dots {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1em;
  color: white;
}

.tier-star-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.augments-text {
  color: white;
}

.Champion-Component {
  width: 12.5%;
  padding: 5px;
}

.Champion-Component-Small {
  width: 25%;
  padding: 0.5em;
}

.Align-Items-Center {
  display: block;
}

.champion-image-large {
  width: 55px;
}

.select {
  background-color: #282c34;
  color: white;
}

.sort-div {
  width: 100%;
}

.tooltip {
  height: auto;
}

.champion-image-small {
  width: 55px;
}

.border-color-1 {
  border: 4px solid #737373;
}

.border-color-2 {
  border: 4px solid #00cc44;
}

.border-color-3 {
  border: 4px solid #3333ff;
}

.border-color-4 {
  border: 4px solid #9900cc;
}

.border-color-5 {
  border: 4px solid #e6e600;
}
.border-color-6 {
  border: 4px solid #e6e600;
}
.border-color-7 {
  border: 4px solid #e6e600;
}
.border-color-8 {
  border: 4px solid #e6e600;
}
.border-color-9 {
  border: 4px solid #e6e600;
}
.border-color-10 {
  border: 4px solid #e6e600;
}
.border-color-11 {
  border: 4px solid #e6e600;
}
.border-color-12 {
  border: 4px solid #e6e600;
}
