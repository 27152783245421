.footer {
  background-color: black;
  min-height: 5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1em;
  color: white;
}
.footer-text{
    max-width: 45em;
}
