

.Header {
    background-color: black;
    /* min-height: 100vh; */
    display: flex;
    flex-direction: row;
    text-align: left;
    justify-content: left;
    font-size: calc(10px + 2vmin);
    color: white;
    font-weight: bold;
  }

.Logo-font{
    padding: .2em;
    padding-left : .5em;
    align-self: center;
}

.Header-logo{
    padding: .1 em;
    padding-left: .5em;
    align-self: center;
    width: 8vh;
    height: 6vh;
}